.main {
  position: relative;
}

.state {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.state__container {
  padding: 10px 16px;
  background: white;
  color: #2196f3;
  font-size: 14px;
  border: 1px dashed #2196f3;
  border-radius: 6px;
}

.state__container--withdrawn {
  border-color: #f74e42;
  color: #f74e42;
}
