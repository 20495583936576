.main {
  position: relative;
  border: 2px solid #c2e0fc;
  border-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -17px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #c2e0fc;
    border: 6px solid #fff;
  }

  &::after {
    content: "";
    position: absolute;
    top: -2px;
    right: -17px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #c2e0fc;
    border: 6px solid #fff;
  }
}

.circles--bottom {
  &::before {
    top: auto;
    bottom: 0;
    transform: translateY(50%);
  }

  &::after {
    top: auto;
    bottom: 0;
    transform: translateY(50%);
  }
}
